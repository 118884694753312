<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="8">
          <el-input placeholder="请输入角色名称搜索" v-model="rolename" clearable>
            <el-button slot="append" icon="el-icon-search" @click="onSearch">搜索</el-button>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div></div>
        </el-col>
        <el-col :span="6" style="float: right;text-align: right">
          <el-button type="success" icon="el-icon-circle-plus-outline" round @click="onAddUnit">新增</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
              :data="tableData"
              stripe
              border
              style="width: 100%">
            <el-table-column type="index"></el-table-column>
            <el-table-column prop="Rolename" label="角色名称"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-tooltip effect="light" content="编辑" placement="top" :hide-after="hideafter">
                  <el-button
                      type="primary"
                      icon="el-icon-edit-outline"
                      circle
                      @click="handleEdit(scope.$index, scope.row)">
                  </el-button>
                </el-tooltip>
                <el-tooltip effect="light" content="删除" placement="top" :hide-after="hideafter">
                  <el-button
                      style="margin-left: 30px"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      @click="handleDelete(scope.$index, scope.row)">
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="菜单操作">
              <template slot-scope="scope">
                <el-tooltip effect="light" content="编辑菜单" placement="top" :hide-after="hideafter">
                  <el-button
                      type="primary"
                      icon="el-icon-menu"
                      circle
                      @click="handleEditMenu(scope.$index, scope.row)">
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-card>

    <!--新增 弹出框操作begin-->
    <el-dialog
        title="新增角色"
        :visible.sync="dialogVisible"
        width="40%"
        @close="oncloseadd">
      <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
        <el-form-item label="角色名称" prop="rolename">
          <el-input v-model="addForm.rolename"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onsave">确 定</el-button>
  </span>
    </el-dialog>
    <!--新增 弹出框操作end-->

    <!--编辑 弹出框操作begin-->
    <el-dialog
        title="修改角色"
        :visible.sync="editdialogVisible"
        width="40%"
        @close="oncloseedit">
      <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
        <el-form-item label="角色名称" prop="rolename">
          <el-input v-model="editForm.rolename"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onedit">确 定</el-button>
  </span>
    </el-dialog>
    <!--编辑 弹出框操作end-->

    <!--编辑 弹出框操作begin-->
    <el-dialog
        title="修改角色菜单"
        :visible.sync="editmenudialogVisible"
        width="60%"
        @close="oncloseeditmenu">
      <el-table
          ref="product"
          :data="tableDatarole"
          stripe
          border
          row-key="Menuid"
          default-expand-all
          :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
          @selection-change="handleSelectionChange"
          @select-all="handleSelectAll">
          height="520"
          style="width: 100%">
        <el-table-column type="selection">
        </el-table-column>
        <el-table-column prop="Menuname" label="菜单名称"></el-table-column>
        <el-table-column prop="Superior" label="上级菜单"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editmenudialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="oneditmenu" :disabled="disabledclick">确 定</el-button>
  </span>
    </el-dialog>
    <!--编辑 弹出框操作end-->

  </div>
</template>

<script>
import {
  AddRole,
  DeleteRole,
  EditRole,
  GetAllMenuList,
  GetAllRoleList, GetRoleMenu,
  GetSearchRoleList, GetTreeAllMenu, GettreeAllMenu,
  GetUserMenu, PostAddRoleMenu
} from "@/network/user";

export default {
  name: "Role",
  data(){
    return{
      rolename:'',
      tableData:[],
      hideafter:2000,
      //新增
      addForm:{
        rolename:''
      },
      //新增用户字段验证规则
      addFormRules:{
        rolename: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字', trigger: 'blur' }
        ]
      },
      //是否显示新增对话框
      dialogVisible:false,
      //编辑部门
      editForm:{
        roleid:'',
        rolename:'',
      },
      //编辑用户字段验证规则
      editFormRules:{
        rolename: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 10, message: '长度在 2 到 10 个字', trigger: 'blur' }
        ]
      },
      //是否显示编辑对话框
      editdialogVisible:false,
      editmenudialogVisible:false,
      //弹窗菜单数据
      tableDatarole:[],
      //弹窗选中的数据
      multipleSelection:[],
      //当前编辑的roleid，弹窗后赋值
      editroid:'',
      disabledclick:false
    }
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      GetAllRoleList(this.$store.state.token).then(res => {
        this.tableData =res.data
      }).catch(err => {
        console.log(err);
      })
    },
    onSearch(){
      if (this.rolename !== ''){
        GetSearchRoleList(this.$store.state.token,this.rolename).then(res => {
          this.tableData =res.data
        }).catch(err => {
          console.log(err);
        })
      }else{
        this.init()
      }
    },
    onAddUnit(){
      this.dialogVisible=true
    },
    handleEdit(index,row){
      this.editdialogVisible =true
      this.editForm.roleid =row.Roleid
      this.editForm.rolename =row.Rolename
    },
    handleDelete(index,row){
      this.$confirm('此操作将删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DeleteRole(this.$store.state.token,row.Roleid).then(res => {
          console.log(res)
          if (res.data===true) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message({
              type: 'warning',
              message: '删除失败!'
            });
          }
          //刷新数据
          if (this.rolename !== '')
          {
            GetSearchRoleList(this.$store.state.token,this.rolename).then(res => {
              this.tableData =res.data
            }).catch(err => {
              console.log(err);
            })
          }else
          {
            this.init()
          }
        }).catch(err => {
          console.log(err);
        })
      }).catch(() => {

      });
    },
    //关闭新增对话框，清除验证
    oncloseadd(){
      this.$refs.addFormRef.resetFields()
    },
    //新增按钮，点击保存
    onsave(){
      this.$refs.addFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('rolename', this.addForm.rolename);
        AddRole(this.$store.state.token,frmDate).then(res => {
          if (res.isadd==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.dialogVisible=false
            if (this.rolename !== '')
            {
              GetSearchRoleList(this.$store.state.token,this.rolename).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //关闭编辑对话框，清除验证
    oncloseedit(){
      this.$refs.editFormRef.resetFields()
    },
    onedit(){
      this.$refs.editFormRef.validate(call=>{
        if (!call) return
        let frmDate = new FormData();
        frmDate.append('roleid', this.editForm.roleid);
        frmDate.append('rolename', this.editForm.rolename);
        EditRole(this.$store.state.token,frmDate).then(res => {
          if (res.isadd==false) {
            this.$message({
              message: res.message,
              type: 'error'
            })
          }else {
            this.$message({
              message: res.message,
              type: 'success'
            })
            this.editdialogVisible=false
            //刷新数据
            if (this.rolename !== '')
            {
              GetSearchRoleList(this.$store.state.token,this.rolename).then(res => {
                this.tableData =res.data
              }).catch(err => {
                console.log(err);
              })
            }else
            {
              this.init()
            }
          }
        }).catch(err => {
          console.log(err);
        })
      })
    },
    //编辑菜单
    handleEditMenu(index,row){
      //显示弹窗
      this.disabledclick=false
      this.editmenudialogVisible=true
      this.editroid=row.Roleid
      //给弹窗列表赋值
      GetTreeAllMenu(this.$store.state.token).then(ress => {
        this.tableDatarole =ress.data
        console.log(this.tableDatarole)
        //给弹窗列表赋值选中列
        GetRoleMenu(this.$store.state.token,row.Roleid).then(res => {
          if (res.data !==null){
            for (let itemx of res.data){
              for (let itemy of this.tableDatarole) {
                if (itemx.Menuid === itemy.Menuid)
                {
                  this.$refs.product.toggleRowSelection(itemy);
                }
                if (itemy.children !==null){
                  for (let itemz of itemy.children) {
                    if (itemx.Menuid === itemz.Menuid) {
                      this.$refs.product.toggleRowSelection(itemz);
                    }
                  }
                }
              }
            }
          }
        }).catch(err => {
          console.log(err);
        })
      }).catch(err => {
        console.log(err);
      })

    },
    oncloseeditmenu(){
      this.multipleSelection=[]
      this.editroid=''
      this.disabledclick=false
    },
    oneditmenu(){
      this.disabledclick=true
      let menurole =''
      for (let item of this.multipleSelection){
        menurole+=item.Menuid+','
      }
      menurole=menurole.length>0?menurole.substring(0,menurole.length-1):menurole
      let frmDate = new FormData();
      frmDate.append('roleid', this.editroid);
      frmDate.append('menuidlist', menurole);
      PostAddRoleMenu(this.$store.state.token,frmDate).then(res => {
        console.log(res);
        if (res.data===false){
          this.$message({
            message: res.Msg,
            type: 'error'
          })
        }
        else{
          this.editmenudialogVisible=false
          this.$message({
            message: res.Msg,
            type: 'success'
          })
        }
      }).catch(err => {
        console.log(err);
      })
    },
    //选中某一条
    handleSelectionChange(val)
    {
      //点击选中会存入multipleSelection，在SelectAll时会触发该方法
      this.multipleSelection = val;
    },
    //选中所有
    handleSelectAll(){
      //tableDatarole为table数据
      for (let item of this.tableDatarole)
      {
        this.$refs.product.toggleRowSelection(item);
        if (item.children !==null){
          for (let item1 of item.children){
            this.$refs.product.toggleRowSelection(item1);
          }
        }
      }
      for (let item of this.tableDatarole)
      {
        this.$refs.product.toggleRowSelection(item);
      }
    }
  }
}
</script>

<style scoped>
.el-table{
  margin: 20px 0px;
}
.el-select{
  width: 100%;
}
</style>